/* 
.carouselContainer {
  height: 24rem;
  overflow: hidden;
  position: relative;
}

.carouselSlide {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carouselSlide img {
  width: 150px;
  height: 150px;
}

.carouselSlide p {
  font-size: 1rem;
  color: #fff;
  margin-top: 0.5rem;
}

.carouselNavigation {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  z-index: 10;
}

.carouselNavigation button {
  background-color: rgba(0, 0, 0, 0.3); 
  border: none;
  color: white;
  padding: 2rem 1rem;
  border-radius: 60%;
  cursor: pointer;
  margin: 0 0.5rem;
  font-size: 1.25rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.carouselNavigation button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}


@media (max-width: 768px) {
  .carouselContainer {
    height: 18rem;
  }

  .carouselSlide img {
    width: 80px;
    height: 80px;
  }

  .carouselSlide p {
    font-size: 0.875rem;
  }
} */
/* Flip card container */
.flip-card {
  background-color: transparent;
  width: 100%;
  height: 200px;
  perspective: 1000px;
  border: 2px solid rgba(255, 255, 255, 0.2); /* Semi-transparent border */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Drop shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
  position: relative;
  cursor: pointer;
}

.flip-card:hover {
  transform: scale(1.08); /* Slightly enlarge card on hover */
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4); /* Increase shadow on hover */
}

@keyframes flipAnimation {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(180deg);
  }
}

.flip-card.flipping .flip-card-inner {
  animation: flipAnimation 0.6s forwards;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s ease-in-out;
  transform-style: preserve-3d;
  border-radius: 10px;
}

@media (min-width: 769px) {
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
}

.flip-card.flipped .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 10px;
}

.flip-card-front {
  background-color: #1f2937;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 0.3s ease;
}

.flip-card-back {
  /* background-color: bg-gray-800; */
  background-color: #334155;
  color: white;
  transform: rotateY(180deg);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: background-color 0.4s ease;
}

.progress-bar {
  width: 80%;
  height: 10px;
  background-color: #555;
  border-radius: 5px;
  margin-top: 8px;
  overflow: hidden;
  pointer-events: none;
}

.progress-fill {
  height: 100%;
  background-color: #00d1b2;
  width: 0%;
  transition: width 0.8s ease;
}

@media (min-width: 1024px) {
  .flip-card {
    width: 240px;
    height: 260px;
  }

  .flip-card-inner {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 768px) {
  /* .flip-card {
    height: 150px;
  }

  .flip-card-inner {
    height: 150px;
  }

  .flip-card-front img,
  .flip-card-back img {
    width: 60px;
    height: 60px;
  } */
  .flip-card {
    height: 150px;
  }

  .flip-card-inner {
    height: 150px;
  }

  .flip-card-front img,
  .flip-card-back img {
    width: 60px;
    height: 60px;
  }

  .flip-card:hover {
    transform: none;
  }
}
/* Flexbox Layout for Skills */
/* #skills {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 32px;
}


.animate-card {
  animation: fadeInUp 1s ease-in-out;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
} */
